import React from 'react';
import PropTypes from 'prop-types';

import "./Button.scss";

const Button = (props) => (
  <>
    {
      props.link ?
        <a href={props.link} className={`btn ${props.modifiers}`}>{props.children}</a> :
        <button className={`btn ${props.modifiers}`}>{props.children}</button>
    }
  </>
);

Button.defaultProps = {
  link: false
};

Button.propTypes = {
  link: PropTypes.string,
  modifiers: PropTypes.string.isRequired
};

export default Button;
