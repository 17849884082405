import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ description, currentpage, lang, meta, keywords, title }) => {
  return (
      <StaticQuery
          query={detailsQuery}
          render={data => {
            const pageTitle = title || data.site.siteMetadata.title;
            const metaDescription = description || data.site.siteMetadata.description;
            const metaKeywords = keywords.length > 0 ? keywords : data.site.siteMetadata.keywords;

            return (
                <Helmet
                    htmlAttributes={{
                      lang,
                      class: `is-page-${currentpage}`,
                    }}
                    title={pageTitle}
                    titleTemplate={`%s | ${data.site.siteMetadata.title}`}
                    meta={[
                        {
                            name: `description`,
                            content: metaDescription,
                        },
                        {
                            property: `og:title`,
                            content: pageTitle,
                        },
                        {
                            property: `og:description`,
                            content: metaDescription,
                        },
                        {
                            property: `og:type`,
                            content: `website`,
                        },
                        {
                            name: `twitter:card`,
                            content: `summary`,
                        },
                        {
                            name: `twitter:creator`,
                            content: data.site.siteMetadata.author,
                        },
                        {
                            name: `twitter:title`,
                            content: pageTitle,
                        },
                        {
                            name: `twitter:description`,
                            content: metaDescription,
                        },
                    ]
                        .concat(
                            metaKeywords.length > 0
                                ? {
                                    name: `keywords`,
                                    content: metaKeywords.join(`, `),
                                }
                                : [],
                        )
                        .concat(meta)}
                >
                  <script>
                    {`
                      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','GTM-N2TFWWC');
                    `}
                  </script>
                </Helmet>
            )
          }}
      />
  )

};

SEO.defaultProps = {
  lang: `pl`,
  currentpage: '',
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string.isRequired,
  currentpage: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default SEO

const detailsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        keywords
      }
    }
  }
`;
