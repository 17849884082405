import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Langs from '../Langs/Langs';
import Menu from "../Menu/Menu";
import SocialMedia from "../SocialMedia/SocialMedia";
import Logo from '../../../shared/icons/logo.svg';
import LogoWhite from '../../../shared/icons/logoWhite.svg';
import './Header.scss';
import {FormattedMessage} from "react-intl";
import {Link} from "gatsby";

class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			menuOpen: false,
			stickyPercentage: 0,
		};
	}

	componentDidMount() {
		document.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.watchForOpenMenuPossible);

		// remove class after open new page
		document.body.classList.remove('menu-open');
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('resize', this.watchForOpenMenuPossible);
	}

	handleScroll = () => {
		// home page on touch devices with width > 1024
		if (document.body.classList.contains('disable-overflow') && window.innerWidth > 1024) {
			return false;
		}

		const percentage = (window.scrollY / 80) * 100;

		this.setState({
			stickyPercentage: percentage > 100 ? 100 : percentage
		});
	};

	toggleMenu() {
		if (this.state.menuOpen === false) {
			document.body.classList.add('menu-open');
		} else {
			document.body.classList.remove('menu-open');
		}

		this.setState({
			menuOpen: !this.state.menuOpen
		})
	}

	watchForOpenMenuPossible = () => {
		if (window.matchMedia("(max-width: 1024px)").matches === false) {
			document.body.classList.remove('menu-open');

			this.setState({
				menuOpen: false,
			});

			if (document.body.classList.contains('disable-overflow')) {
				// remove white header after resize on homepage
				this.setState({
					stickyPercentage: 0,
				});
			}
		}
	};

	handleMenuClick(){
		document.body.classList.remove('menu-open');

		this.setState({
			menuOpen: false,
		});
	}

	render() {
		return (
			<header
				className={"header" + (this.state.stickyPercentage > 30 ? ' header--sticky' : '')}
				style={{backgroundColor: 'rgba(255,255,255,' + this.state.stickyPercentage / 100 + ')'}}>
				<Link to="/#bardziej" className="header__brand">
					<LogoWhite className="header__logo header__logo--white"/>
					<Logo className="header__logo header__logo--color"/>
				</Link>
				<button className="header__menu-button" onClick={this.toggleMenu.bind(this)}>
					<span className="header__menu-text">
						<FormattedMessage id="Menu"/>
					</span>
					<span className="header__menu-icon"><span/></span>
				</button>
				<nav className={"header__menu" + (this.state.menuOpen ? ' header__menu--open' : '')}>
					<Menu onMenuClick={this.handleMenuClick.bind(this)}/>
					<Langs/>
					<SocialMedia/>
				</nav>
			</header>
		)
	}
}

Header.propTypes = {
	siteTitle: PropTypes.string
};

Header.defaultProps = {
	siteTitle: ``
};

export default Header;
