import React from 'react';
import "./OfficeBox.scss";
import PropTypes from 'prop-types';
import Button from "../Button";

const OfficeBox = props => (
    <div className={`office-box${props.customStyle ? ' office-box--custom' : ''}`}>
      <div className="office-box__label">{props.label}</div>
      <h2 className="office-box__place">{props.place}</h2>
      <div className="office-box__bottom">
        <div className="office-box__address">
          <div className="office-box__street">{props.street}</div>
          <div className="office-box__city">{props.city}</div>
        </div>
        <div className="office-box__contact">
          <a className="office-box__phone" href={`tel:${props.phone}`}>{props.phone}</a>
          <a className="office-box__email" href={`mailto:${props.email}`}>{props.email}</a>
        </div>
      </div>
      {props.coords && <Button link={props.coords} modifiers="btn--outline">Zobacz na mapie</Button>}
    </div>
);

OfficeBox.defaultProps = {
  customStyle: false,
  coords: ''
};

OfficeBox.propTypes = {
  customStyle: PropTypes.bool,
  coords: PropTypes.string,
  label: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
};

export default OfficeBox;
