import React, {Component} from 'react';
import {graphql, StaticQuery} from "gatsby";

import './Slider.scss';
import Img from "gatsby-image";
import Tabs from "../UI/Tabs/Tabs";

class BardziejSlider extends Component {
  render() {
    const {data} = this.props;
    return (
      <div className="bardziej-slider">
        <div className="bardziej-slider__background">
          <Img loading="eager" className='image' fluid={data.top.childImageSharp.fluid} />
        </div>
        <div className="bardziej-slider__slides">
          <Tabs showNav={false} showArrows={false} autoplay={true}>
            <div label="design">
              <div className="bardziej-slider__slide">
                <div className="bardziej-slider__text">Design</div>
                <div className="bardziej-slider__object">
                  <Img loading="eager" className='image' fluid={data.slide1.childImageSharp.fluid} />
                </div>
              </div>
            </div>
            <div label="kreujemy">
              <div className="bardziej-slider__slide">
                <div className="bardziej-slider__text">kreujemy</div>
                <div className="bardziej-slider__object">
                  <Img loading="eager" className='image' fluid={data.slide2.childImageSharp.fluid} />
                </div>
              </div>
            </div>
            <div label="reklama">
              <div className="bardziej-slider__slide">
                <div className="bardziej-slider__text">reklama</div>
                <div className="bardziej-slider__object">
                  <Img loading="eager" className='image' fluid={data.slide3.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    )
  }
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        top: file(relativePath: { eq: "bardziej/slider/bg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        slide1: file(relativePath: { eq: "bardziej/slider/design.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        slide2: file(relativePath: { eq: "bardziej/slider/kreujemy.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        slide3: file(relativePath: { eq: "bardziej/slider/reklama.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <BardziejSlider data={data} {...props} />}
  />
);

