import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Field.scss';
import {injectIntl, intlShape, FormattedMessage, FormattedHTMLMessage} from 'react-intl'
import Attachment from "../../shared/icons/attachment.svg";

class Field extends Component {

  constructor(props) {
    super(props);
    this.state ={
      fileName: null
    }
  }

  handleChangeFile(event) {
    const file = event.target.files[0];
    if (file) {
      const name = file.name;
      this.setState({
        fileName: name
      })
    } else {
      this.setState({
        fileName: null
      })
    }
  }

  render() {
    const {intl, type, size, name, placeholder} = this.props;
    switch (type) {
      case "text":
        return (
          <div className={`field field--${type} field--w${size}`}>
            <input type={type} name={name} id={name} placeholder={intl.formatMessage({ id: placeholder })} />
          </div>
        );
      case "textarea":
        return (
          <div className={`field field--${type} field--w${size}`}>
            <textarea name={name} id={name} placeholder={intl.formatMessage({ id: placeholder })} />
          </div>
        );
      case "checkbox":
        return (
          <div className={`field field--${type} field--w${size}`}>
            <input type={type} name={name} id={name} />
            <label htmlFor={name}>
              <FormattedMessage id={placeholder} />
            </label>
          </div>
        );
      case "file":
        return (
          <div className={`field field--${type} field--w${size}`}>
            <input type={type} name={name} id={name} onChange={(e) => this.handleChangeFile(e)} />
            <label htmlFor={name}>
              <Attachment className="icon" />
              <span className="field__text">
              {this.state.fileName ? this.state.fileName : <FormattedHTMLMessage id={placeholder} /> }
            </span>
            </label>
          </div>
        );
      default:
        return null;
    }
  }

};

Field.defaultProps = {
  size: `100`
};

Field.propTypes = {
  intl: intlShape.isRequired,
  type: PropTypes.oneOf(['text', 'textarea', 'checkbox', 'file']),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  size: PropTypes.string
};

export default injectIntl(Field);
