import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Tab.scss';

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { index, onClick } = this.props;
    onClick(index);
  };

  render() {
    const {
      onClick,
      props: {
        activeTab,
        index,
        label,
        labelTag: Tag
      },
    } = this;

    let className = 'tab-list-item';

    if (activeTab === index) {
      className += ' tab-list-item--active';
    }

    return (
      <div
        className={className}
        onClick={onClick}
      >
        <Tag>{label}</Tag>
      </div>
    );
  }
}

Tab.defaultProps = {
  labelTag: "span"
};

export default Tab;
