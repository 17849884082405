import React, {useContext} from 'react';
import {injectIntl} from 'react-intl';
import locales from '../../../i18n/locales';
import PageContext from '../../../layout/PageContext';
import LanguagesPath from "../../../i18n/LanguagesPath";
import './Langs.scss';
import {Link} from "gatsby";

const Langs = ({intl: {locale}}) => {
	const pageContext = useContext(PageContext);

	const getLocaleUrl = locale => {
		const {originalPath} = pageContext.page;
		const languagesPath = new LanguagesPath(originalPath, locale);

		return languagesPath.getSlug();
	};

	if (!pageContext.custom.localeKey) return null;

	return (
		<div className="lang">
			{locales.map(language => (
				language.locale !== locale &&
				<Link key={language.id} to={getLocaleUrl(language.locale)} className="lang__item">{language.label}</Link>
			))}
		</div>
	);
};

export default injectIntl(Langs);
