import React from 'react';
import PropTypes from 'prop-types';
import Field from "./Field";
import Download from "../../shared/icons/download.svg";
import {FormattedHTMLMessage} from "react-intl";
import BardziejButton from "../BardziejButton";

import './ContactForm.scss';

const ContactForm = (props) => (
	<div className={`form-container ${props.transparent ? 'form-container--transparent' : ''}`}>
		<form action="" className="form-grid">
			<Field type="text" name="name" placeholder="form.name" size="50"/>
			<Field type="text" name="company" placeholder="form.company" size="50"/>
			<Field type="text" name="email" placeholder="form.mail" size="50"/>
			<Field type="text" name="phone" placeholder="form.phone" size="50"/>
			<Field type="textarea" name="message" placeholder="form.message" size="100"/>
			<Field type="checkbox" name="terms" placeholder="form.check1" size="100"/>
			<div className="submit-row">
				<div className="submit-row__left">
					<div className="icon-box">
						<a href="#todo">
							<Download className="icon"/>
							<span className="icon-box__text">
									<FormattedHTMLMessage id="form.downloadBrief"/>
								</span>
						</a>
					</div>
					<Field type="file" name="file" placeholder="form.uploadBrief" size="100"/>
				</div>
				<div className="submit-row__right">
					<BardziejButton modifier={`${props.transparent ? 'bardziej-button--white' : ''}`} title="form.send"
					                type="submit"/>
				</div>
			</div>
		</form>
	</div>
);

ContactForm.propTypes = {
	standalone: PropTypes.bool,
	transparent: PropTypes.bool
};

ContactForm.defaultProps = {
	standalone: false,
	transparent: false
};

export default ContactForm;
