import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import './Heading.scss';

const Heading = ({size, modifiers, title, titleTag: Tag, attrs}) => (
  <div className={`heading heading--${size} ${modifiers ? modifiers : ''}`}>
    <FormattedMessage id={title}>
      {(txt) => (
        <Tag {...attrs}>
          <span className="text">{txt}</span>
        </Tag>
      )}
    </FormattedMessage>
  </div>
);

Heading.defaultProps = {
  titleTag: "span"
};

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string,
  size: PropTypes.string.isRequired,
  modifiers: PropTypes.string,
  attrs: PropTypes.object
};

export default Heading;
