import React from 'react';
import TextContent from "../../Typography/TextContent";

const PopupHeading = ({children, icon: Icon}) => (
  <div className="popup__heading">
    {Icon && <Icon />}
    <TextContent modifiers={['normal', 'bold', 'small-mobile']}>
      {children}
    </TextContent>
  </div>
);

export default PopupHeading;
