const locales = require('./locales');
const translationsFileContent = require('./slugs.json');

module.exports = class Languages {
  constructor(path, locale) {
    this.path = path;
    this.locale = locale || this.DEFAULT_LOCALE || 'en';
    this.localizedPath = this.getLocalizedPath();
    this.localesTranslations = translationsFileContent[locale];
  };

  get DEFAULT_LOCALE() {
    return this.constructor.getDefaultLocale();
  }

  set translations(translations) {
    this.localesTranslations = translations[this.locale];
  }

  static getDefaultLocale() {
    return locales.find(lang => lang.default).locale;
  }

  getLocalizedPath() {
    return this.locale === this.DEFAULT_LOCALE
      ? this.path
      : `${this.locale}${this.path}`;
  }

  getPathChunks() {
    return this.localizedPath.split('/');
  }

  getReducedSlug() {
    return this.getPathChunks().reduce((reducedArray, current) => {
      if (current !== '') reducedArray.push(current);
      return reducedArray;
    }, []);
  }

  getTranslatedReducedSlug() {
    return this.reducedSlug.map(chunk => {
      if (this.localesTranslations[chunk]) {
        return this.localesTranslations[chunk];
      } else {
        return chunk;
      }
    });
  }

  getTranslatedSlug() {
    if (this.translatedReducedSlug) {
      return '/' + this.translatedReducedSlug.join('/');
    } else {
      return '/' + this.reducedSlug.join('/');
    }
  }

  getSlug() {
    this.reducedSlug = this.getReducedSlug();
    this.translatedReducedSlug = this.getTranslatedReducedSlug();

    return this.getTranslatedSlug().substr(-1) === '/' || this.getTranslatedSlug().indexOf('#') > 0 ? this.getTranslatedSlug() : `${this.getTranslatedSlug()}/`;
  }

};
