import React from 'react';
import './Menu.scss';
import Link from "../../Link";

const Menu = ({onMenuClick}) => (
	<ul className="nav">
		<li className="nav__item"><Link to="/#bardziej" onClick={onMenuClick}>Bardziej</Link></li>
		<li className="nav__item"><Link to="/#my" onClick={onMenuClick}>My</Link></li>
		<li className="nav__item"><Link to="/#kompetencje" onClick={onMenuClick}>Kompetencje</Link></li>
		<li className="nav__item"><Link to="/#projekty" onClick={onMenuClick}>Projekty</Link></li>
		<li className="nav__item"><Link to="/#klienci" onClick={onMenuClick}>Klienci</Link></li>
		<li className="nav__item"><Link to="/#kontakt" onClick={onMenuClick}>Kontakt</Link></li>
		<li className="nav__item nav__item--space"><a href="https://www.intellect.pl/blog/">Blog</a></li>
	</ul>
);

export default Menu;
