import React from 'react';
import PropTypes from 'prop-types';

const Spacer = ({contentTag: Tag, modifiers, classes}) => {
  let componentClasses = modifiers.map(item => "spacer--" + item).join(' ');
  if (classes.length) {
    componentClasses += ' ' + classes.join(' ');
  }
  return (
    <Tag className={`spacer ${componentClasses}`} />
  )
};

Spacer.defaultProps = {
  contentTag: 'div',
  modifiers: [],
  classes: []
};

Spacer.propTypes = {
  contentTag: PropTypes.string,
  modifiers: PropTypes.array,
  classes: PropTypes.array,
};

export default Spacer;
