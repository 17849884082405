import React from 'react';

import Facebook from '../../../shared/icons/facebook.svg'
import Linkedin from '../../../shared/icons/linkedin.svg'
import Instagram from '../../../shared/icons/instagram.svg'
import EU from '../../../shared/icons/eu.svg'
import './SocialMedia.scss';
import Link from "../../Link";

const SocialMedia = () => (
	<div className="social-media">
		<a href="https://www.facebook.com/intellectagencja/" className="social-media__item" target="_blank"
		   rel="nofollow noopener noreferrer">
			<Facebook className="social-media__icon"/>
		</a>
		<a href="https://www.linkedin.com/company/agencja-interaktywna-intellect/?originalSubdomain=pl"
		   className="social-media__item" target="_blank" rel="nofollow noopener noreferrer">
			<Linkedin className="social-media__icon"/>
		</a>
		<a href="https://www.instagram.com/agencja_intellect/" className="social-media__item" target="_blank"
		   rel="nofollow noopener noreferrer">
			<Instagram className="social-media__icon"/>
		</a>
		<Link to={'/unia-europejska/'} className="social-media__item social-media__item--eu">
			<EU className="social-media__icon" />
		</Link>
	</div>
);

export default SocialMedia;
