import React from 'react';
import "./ContactCard.scss";
import PropTypes from "prop-types";

const ContactCard = props => (
    <div className="contact-card">
      <div className="contact-card__image">
        {props.children}
        <div className={`contact-card__content${props.customStyles ? ' contact-card__content--' + props.customStyles : ''}`}>
          <div className="contact-card__name">{`${props.name}`}<br /> {`${props.surname}`}</div>
          <div className="contact-card__position">{props.position}</div>
          <div className="contact-card__contact">
            <a className="contact-card__phone" href={`tel:${props.phone}`}>{props.phone}</a>
            <a className="contact-card__email" href={`mailto:${props.email}`}>{props.email}</a>
          </div>
        </div>
      </div>
    </div>
);

ContactCard.defaultProps = {
  customStyles: ""
};

ContactCard.propTypes = {
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  customStyles: PropTypes.string
};

export default ContactCard;
