import React, {Component} from 'react';

import "./Popup.scss";

import CloseIcon from '../../../shared/icons/close.svg';

class Popup extends Component {
  componentDidMount() {
    document.body.classList.add('overflow');
  }

  componentWillUnmount() {
    document.body.classList.remove('overflow');
  }

  render() {
    const {children} = this.props;
    return (
      <div className="popup">
        <div className="popup__container">
          <button className="popup__close" onClick={this.props.closePopup}>
            <CloseIcon />
          </button>
          <div className="popup__container-inner">
            {children}
          </div>
        </div>
      </div>
    )
  }
};

export default Popup;
