import React from 'react';
import styled from 'styled-components';

const List = ({items, space}) => {

  const StyledList = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      padding-left: 20px;
      position: relative;
      margin-bottom: ${space}px;
      text-align: left;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 1px;
        background-color: #555351;
        position: absolute;
        left: 0;
      }
    }
  `;

  return (
    <StyledList className="styled-list">
      {items.map(item => (
        <li key={item}>{item}</li>
      ))}
    </StyledList>
  )
};

List.defaultProps = {
  space: 30
};

export default List;
