import React, {Component} from "react";
import PropTypes from 'prop-types';
import {StaticQuery, graphql} from "gatsby"

import Img from "gatsby-image"
import ContactCard from './ContactCard';
import OfficeBox from './OfficeBox';
import ContactForm from '../Form/ContactForm';
import Heading from '../../components/Typography/Heading';

import './ContactSlide.scss';

class ContactSlide extends Component {

	constructor(props) {
		super(props);

		this.state = {
			smallPhotos: false,
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.checkPhotoSize);

		this.checkPhotoSize();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.checkPhotoSize);
	}

	checkPhotoSize = () => {
		this.setState({
			smallPhotos: window.matchMedia("(max-width: 640px)").matches,
		});
	};

	render() {
		return (
			<StaticQuery
				query={graphql`
			        query AstronautImageQuery {
			            konradDesktop: file(relativePath: { eq: "kontakt-konrad-desktop.jpg" }) {
				          childImageSharp {
				              fixed(width: 450, quality: 100) {
				              ...GatsbyImageSharpFixed
				            }
				          }
				        },
				        konradMobile: file(relativePath: { eq: "kontakt-konrad-mobile.jpg" }) {
				          childImageSharp {
				              fixed(width: 640, quality: 100) {
				              ...GatsbyImageSharpFixed
				            }
				          }
				        },
			            paulinaDesktop: file(relativePath: { eq: "kontakt-paulina-desktop.jpg" }) {
				          childImageSharp {
				              fixed(width: 450, quality: 100) {
				              ...GatsbyImageSharpFixed
				            }
				          },
			            }
			            paulinaMobile: file(relativePath: { eq: "kontakt-paulina-mobile.jpg" }) {
				          childImageSharp {
				              fixed(width: 640, quality: 100) {
				              ...GatsbyImageSharpFixed
				            }
				          }
			            }
			        }
      `}
		render={data => (
			<section
				className={`${this.props.standalone ? 'standalone-slide' : 'homepage-slide'} contact-slide`}
				id="kontakt">
				<div className="slide-column">
					<ContactCard
						name="Konrad"
						surname="Kurek"
						position="new business"
						phone="+48 (18) 88 80 240"
						email="konrad.kurek@intellect.pl">
						<Img className="image" fixed={this.state.smallPhotos ? data.konradMobile.childImageSharp.fixed : data.konradDesktop.childImageSharp.fixed}/>
					</ContactCard>
					<ContactCard
						name="Paulina"
						surname="Kowalska"
						position="support IT"
						phone="+48 (18) 88 80 240"
						email="paulina.kowalska@intellect.pl">
						<Img className="image" fixed={this.state.smallPhotos ? data.paulinaMobile.childImageSharp.fixed : data.paulinaDesktop.childImageSharp.fixed}/>
					</ContactCard>
				</div>
				<div className="slide-column">
					<div className="slide-content">
						<Heading title="form.formTitle" titleTag={this.props.titleTag}
						         modifiers={this.props.headingModifiers}
						         size={`${this.props.standalone ? 'medium-2x' : 'small'}`}/>
						{!this.props.standalone && <div className="office-boxes">
							<OfficeBox
								label="Biuro"
								place="Limanowa"
								street="ul. Józefa Jońca 22"
								city="34-600 Limanowa"
								phone="+48 18 33 72 910"
								email="biuro@intellect.pl"/>
							<OfficeBox
								label="Biuro"
								place="Kraków"
								street="Opolska 18"
								city="31-323 Kraków"
								phone="+48 18 33 72 910 "
								email="krakow@intellect.pl"/>
							<OfficeBox
								label="Biuro"
								place="Warszawa"
								street="ul. Jana Kazimierza 60/270"
								city="01-248 Warszawa"
								phone="+48 22 100 47 05"
								email="warszawa@intellect.pl"/>
						</div>}
						<ContactForm standalone={this.props.standalone}/>
					</div>
				</div>
			</section>)}/>
		);
	}
}

ContactSlide.propTypes = {
	standalone: PropTypes.bool,
	headingModifiers: PropTypes.string.isRequired,
};

ContactSlide.defaultProps = {
	standalone: false,
	titleTag: "span",
	headingModifiers: '',
};

export default ContactSlide;
