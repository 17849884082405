import React, {Component} from "react";

import './ScrollableVideo.scss';
import {withPrefix} from "../../../.cache/gatsby-browser-entry";

class ScrollableVideo extends Component {

	constructor(props) {
		super(props);

		this.state = {
			playerSticky: false,
			playerBottom: false,
		};

		this.videoRef = null;
		this.setVideoRef = (ref) => {
			this.videoRef = ref;
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = (event) => {
		const playerBounding = this.videoRef.getBoundingClientRect();

		// console.log(playerBounding.y);

		// const halfScreenOfTop = (window.innerHeight - playerBounding.height) / 2;
		// const visibleTop = playerBounding.y < halfScreenOfTop;
		// const visibleBottom = playerBounding.y + playerBounding.height > playerBounding.y + playerBounding.height;

		if (playerBounding.y + playerBounding.height/2 < window.innerHeight && playerBounding.y > 0) {
			this.videoRef.play();
			// this.setState({
			// 	playerSticky: true,
			// });
			// console.log('niby sticky');
			// this.videoRef.pause();
			// const scrollPercentage = (halfScreenOfTop - scrollableVideo.y) / 1000;
			// const newDuration = Math.round(scrollPercentage * 30);
			// console.log(newDuration);
			// this.videoRef.currentTime = newDuration;

		} else {
			this.videoRef.pause();
			// console.log('i nie sticky');
			//
			// this.setState({
			// 	playerSticky: false,
			// 	playerBottom: visibleBottom,
			// });
			// this.videoRef.pause();
			// this.videoRef.currentTime = 0;
		}
	};

	render() {
		return (
			<video ref={this.setVideoRef} className="basic-video" muted playsInline loop>
				<source src={withPrefix(this.props.video)} type="video/mp4"/>
			</video>
		);

		// return (
		// 	<div className={"scrollable-video"}>
		// 		<div className={"scrollable-video__player" + (this.state.playerSticky ? ' scrollable-video__player--sticky' : (this.state.playerBottom ? ' scrollable-video__player--bottom' : ''))}>
		//
		// 		</div>
		// 	</div>
		// );
	}
}

export default ScrollableVideo;