import React, {Component} from 'react';
import './Footer.scss';
import Link from '../../Link';
import {FormattedHTMLMessage} from "react-intl";
import Popup from "../../UI/Popup/Popup";
import PopupHeading from "../../UI/Popup/PopupHeading";
import PopupContent from "../../UI/Popup/PopupContent";
import TextContent from "../../Typography/TextContent";
import List from "../../Typography/List";
import Spacer from "../Helpers/Spacer";
import CookieAlert from '../../Kits/CookieAlert';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPopupPolicy: false,
		};
	}

	togglePopupPolicy() {
		this.setState({
			showPopupPolicy: !this.state.showPopupPolicy
		});
	}

	render() {
		return (
			<>
				{this.state.showPopupPolicy &&
					<Popup closePopup={this.togglePopupPolicy.bind(this)}>
						<PopupHeading>
							Polityka ochrony prywatności
						</PopupHeading>
						<PopupContent>
							<TextContent modifiers={['small']}>
								<p>Firma Intellect.pl właściciel serwisu internetowego www.intellect.pl zobowiązuje się do ochrony danych
									dotyczących Klientów.</p>
							</TextContent>
							<List space={15} items={[
								'Właściciel serwisu gromadzi statystyki odwiedzalności serwisu.',
								'Dane przekazywane przez Klientów Firmy Intellect będą wykorzystywane jedynie w celu komunikacji.',
								'Dane przekazywane przez Klientów Firmy Intellect nie będą przekazywane ani sprzedawane innym firmom ani osobom trzecim.',
								'Intellect.pl zastrzega sobie prawo do wprowadzenia zmian w polityce ochrony prywatności.'
							]}/>
							<Spacer/>
							<TextContent modifiers={['small', 'bold']}>
								<p>Informacje dotyczące oprogramowania, którego autorem jest Intellect:</p>
							</TextContent>
							<List space={15} items={[
								'Klient, który zakupił oprogramowanie nie jest upoważniony do modyfikacji kodu źródłowego oprogramowania bez zgody autora.',
								'Klient, który zakupił oprogramowanie nie jest upoważniony do sprzedaży oprogramowania.',
								'Klient posiadający licencję na oprogramowanie może używać tylko jednej jego kopii.',
								'Klient nie może przenieść właśności oprogramowania na osobę trzecią bez zgody i wiedzy Intellect.pl.'
							]}/>
							<Spacer modifiers={['medium']}/>
							<TextContent modifiers={['small']}>
								<p>Jeśli mają Państwo dodatkowe pytania, prosimy o kontakt za pomocą <Link to="/#kontakt">formularza kontaktowego</Link></p>
							</TextContent>
						</PopupContent>
					</Popup>
				}
				<CookieAlert showCookiePolicy={this.togglePopupPolicy.bind(this)} />
				<footer className="footer">
					<div className="wrapper">
						<div className="footer__layout">
							<div className="footer__left">
								<nav className="footer-nav">
									<ul className="footer-nav__list">
										<li className="footer-nav__item"><Link activeClassName="active" to="/">Kariera</Link></li>
										<li className="footer-nav__item"><a className="link" onClick={this.togglePopupPolicy.bind(this)}>Polityka prywatności</a></li>
									</ul>
								</nav>
							</div>
							<div className="footer__right">
								<FormattedHTMLMessage id="footer.copyright" />
							</div>
						</div>
					</div>
				</footer>
			</>
		)
	}
}
export default Footer;
