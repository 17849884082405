import React from 'react';
import PropTypes from 'prop-types';

import './TextContent.scss';

const TextContent = ({contentTag: Tag, modifiers, classes, children}) => {
  let componentClasses = modifiers.map(item => "text-content--" + item).join(' ');
  if (classes.length) {
    componentClasses += ' ' + classes.join(' ');
  }
  return (
    <Tag className={`text-content ${componentClasses}`}>
      {children}
    </Tag>
  )
};

TextContent.defaultProps = {
  contentTag: 'div',
  modifiers: [],
  classes: []
};

TextContent.propTypes = {
  contentTag: PropTypes.string,
  modifiers: PropTypes.array,
  classes: PropTypes.array,
};

export default TextContent;
