import React from "react";
import BardziejIcon from "../shared/icons/bardziej.svg";
import CloseIcon from "../shared/icons/close.svg";
import './BardziejButton.scss';
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import Link from "./Link";

const ButtonType = ({href, hrefext, type, modifier, onClick, children}) => {
	if (href) {
		return (
			<Link to={href} className={`bardziej-button ${modifier}`}>
				{children}
			</Link>
		);
	}

	if (hrefext) {
		let target = '_blank';

		if (hrefext.indexOf('mailto:') >= 0) {
			target = null;
		}

		return (
			<a href={hrefext} target={target} className={`bardziej-button ${modifier}`}>
				{children}
			</a>
		);
	}

	return (
		<button type={type} className={`bardziej-button ${modifier}`} onClick={onClick}>
			{children}
		</button>
	);
};

const BardziejButton = (props) => (
	<ButtonType {...props}>
		<span className="bardziej-button__text">
			{props.closable ? <FormattedMessage id={props.closableText}/> : <FormattedMessage id={props.title}/>}
		</span>
		{props.closable ? <CloseIcon className="bardziej-button__icon"/> : <BardziejIcon className="bardziej-button__icon"/>}
	</ButtonType>
);

BardziejButton.defaultProps = {
	type: 'button',
	modifier: ''
};

BardziejButton.propTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	modifier: PropTypes.string
};

export default BardziejButton;
