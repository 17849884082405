import browserLang from 'browser-lang';
import locales from './locales';
import LanguagesPath from "./LanguagesPath";

const fallbackLang = LanguagesPath.getDefaultLocale();

export default () =>
  localStorage.getItem('locale') ||
  browserLang({
    languages: locales.map(locale => locale.locale),
    fallback: fallbackLang,
  });
