import React from 'react';
import PropTypes from 'prop-types';
import {StaticQuery, graphql} from 'gatsby';
import {IntlProvider, addLocaleData} from 'react-intl';

import PageContext from './PageContext';

// i18n
import plLocale from 'react-intl/locale-data/pl';
import enLocale from 'react-intl/locale-data/en';
import {translations} from '../i18n';
import Languages from '../i18n/LanguagesPath';

import Header from '../components/layout/Header/Header';
import Footer from '../components/layout/Footer/Footer';
import SEO from '../components/SEO';

import '../shared/styles/app.scss';

addLocaleData([...plLocale, ...enLocale]);

const withLayout = customProps => PageComponent => props => {
	const {locale} = props.pageContext;
	const {localeKey} = customProps;

	const pageContextValue = {custom: customProps, page: props.pageContext};

	const pageLocale = locale || Languages.getDefaultLocale();
	const pageTitle = locale ? translations[locale][`${localeKey}.title`] : '';

	return (
		<StaticQuery
			query={graphql`
                query SiteTitleQuery {
                  site {
                    siteMetadata {
                      title
                    }
                  }
                }
            `}
			render={
				data => (
					<IntlProvider locale={pageLocale} messages={translations[pageLocale]}>
						<PageContext.Provider value={pageContextValue}>
							<div className="page-body">
								<SEO title={pageTitle} lang={locale} currentpage={localeKey}/>
								<div className="overlay"/>
								<Header siteTitle={data.site.siteMetadata.title}/>
								<main>
									<PageComponent {...props} />
								</main>
								<Footer/>
							</div>
						</PageContext.Provider>
					</IntlProvider>
				)
			}
		/>
	)
};

withLayout.defaultProps = {
	colorHeader: false
};

withLayout.propTypes = {
	children: PropTypes.node.isRequired
};

export default withLayout;
