import flatten from "flat";

const messages = {
	error404: {
		'NOT FOUND': 'Strony nie znaleziono',
		'You just hit a route that doesnt exist the sadness': "You just hit a route that doesn't exist... the sadness.",
	},
	home: {
		title: 'Strona główna',
		claim1: 'Rozumiemy',
		claim2: 'bardziej',
		button: 'Co to oznacza?',
		more: 'Sprawdź',
		we: {
			title: 'Zwinni bardziej',
			subtitle: 'Kim jesteśmy',
			text: 'Specjalizujemy się w dostarczaniu naszym Klientom tego, czego chcą dokładnie wtedy, kiedy tego potrzebują. Dzięki adaptacji do ciągle zmieniających się wymagań dostarczamy wartość biznesową w możliwie najkrótszym czasie #tuiteraz – wszystko w oparciu o kilkunastoletnią wiedzę i zgrany zespół specjalistów o różnych kompetencjach. Zwinni bardziej, to grono ekspertów w swoich dziedzinach, znających wiele sposobów na osiągnięcie tego samego celu, a wybierający ten optymalny do jak najlepszej realizacji projektu. ',
			button: 'Poznaj nas',
		},
		competences: {
			title: 'Kompetencje bardziej',
			text: 'Software mamy w jednym palcu, a kreatywność w drugim - podczas codziennej pracy stykamy się nimi, jak na najsłynniejszym fresku Michała Anioła. I tak powstają nasze najlepsze projekty - wierzymy, że wspólnie możemy więcej bardziej. Jesteśmy zwinną organizacją o szerokich kompetencjach, które analogicznie do półkul mózgowych wzajemnie się uzupełniają.',
			marketing: {
				name: 'Creative brain',
				description: 'Komunikacja&Marketing',
			},
			software: {
				name: 'Tech brain',
				description: 'Software house',
			},
			more: 'Rozwiń',
			close: 'Zamknij',
		},
		marketingCompetences: {
			1: {
				title: 'Strategia marketingowa',
				desc: 'Do zadań podchodzimy taktycznie, do pracy zespołowo, a do marketingu strategicznie! Bo nawet najlepsze pomysły nie są nic warte bez doskonałego planu!',
			},
			2: {
				title: 'Social Media',
				desc: 'Sprawimy, że o Twojej marce będzie głośno w socialach, społeczność ją pokocha, a polubienia, komentarze i udostępnienia będziesz zbierać garściami!',
			},
			3: {
				title: 'Content Marketing',
				desc: 'Budujemy marki z opowieścią, pełne wartościowej treści! Tworzymy angażujący content, który aż chce się bardziej czytać - na blogach, portalach i stronach firmowych!',
			},
			4: {
				title: 'Video Marketing',
				desc: 'Kręcimy content video i nabijamy wyświetlenia Twoim filmom! A wszystko spójnie ze strategią i zgodnie z planem!',
			},
			5: {
				title: 'E-mail Marketing',
				desc: 'Wysyłamy tylko takie maile, które chce się otwierać. CTR musi się zgadzać!',
			},
			6: {
				title: 'e-PR',
				desc: 'Wybieramy najlepsze kanały, ruszamy w digital i robimy pozytywny szum wokół Twojej marki! Wszystko po to, by konsumenci ją kochali!',
			},
			7: {
				title: 'Wizerunek marki (branding)',
				desc: 'Tworzymy marki z sukcesem - od nazwy i claimu, przez ideę, aż po wygląd. Nasz branding wyróżni Cię bardziej! Po prostu.',
			},
			8: {
				title: 'SEO > Pozycjonowanie',
				desc: 'Pozycjonowanie przynosi korzyści dla Twojego biznesu tylko wtedy, gdy jesteś w top10. A my wiemy, jak to zrobić, więc daj się wyszukać.',
			},
			9: {
				title: 'Kampanie reklamowe',
				desc: 'Duża porcja widoczności, szczypta kreacji zmieszana z aromatycznym PR-em i doprawiona starannie dobranymi słowami kluczowymi - to przepis na udaną kampanię. Serwujemy ją tak, aby przyniosła Ci sukces.',
			},
			10: {
				title: 'Analityka Internetowa',
				desc: 'Z oceanu danych wyławiamy tylko te istotne dla Twojego biznesu. Na ich podstawie obieramy właściwy kurs, żeby płynąć prosto po sukces Twojej firmy!',
			},
			11: {
				title: 'Google Ads',
				desc: 'BREAKING NEWS: Google Ads dociera do ponad 90% użytkowników Internetu. Wiemy, jak wykorzystać ten potencjał, aby pracował dla Ciebie!',
			},
		},
		softwareCompetences: {
			1: {
				title: 'Strony internetowe',
				desc: 'Robimy dobry design, oswajamy UX i technologię - idziemy pod prąd, by pokazywać marki od najlepszej strony. Z nami w digitalu jesteś bardziej obecny! ',
			},
			2: {
				title: 'Sklepy internetowe / E-commerce',
				desc: 'Robimy grube “ekomersy”. Budujemy e-sklepy, integrujemy systemy i automatyzujemy marketing, po to byś sprzedawał więcej.',
			},
			3: {
				title: 'Portale internetowe',
				desc: 'Nasze portale to tysiące użytkowników, masa newsów, ogłoszeń oraz ogrom reklam. Budujemy wydajne i użyteczne platformy, gotowe na potężny ruch.',
			},
			4: {
				title: 'Systemy dedykowane',
				desc: 'Twoja unikalna wizja zyska z nami na technologicznym! charakterze. Nasze dedykowane systemy dały początek wielu niecodziennym startupom i jesteśmy z tego dumni! ',
			},
			5: {
				title: 'Systemy CRM/ERP',
				desc: 'Wejdź na wyższy poziom zarządzania firmą i obsługą klienta. Bezbłędnie przełożymy procesy biznesowe Twojej organizacji na system dostosowany w pełni do jej specyfiki.',
			},
			6: {
				title: 'Rozwiązania/aplikacje mobilne',
				desc: 'Jesteśmy przekonani, że każdy rok to rok mobile. Projektujemy i wdrażamy aplikacje mobilne, będące odpowiedzią na codzienne potrzeby ludzi. ',
			},
			7: {
				title: 'Usługi serwerowe',
				desc: 'Hosting? Dedykowana maszyna? My dobierzemy i skonfigurujemy dla Ciebie rozwiązanie, które nigdy nie zawiedzie. A do tego nasi admini są sympatyczni.',
			},
		},
		realizations: {
			claim: 'Realizacje bardziej',
			see: 'Zobacz',
			lemon: {
				heading: "Lemon Resort",
				textHeading: "Malowniczo położony hotel\n nad jeziorem w górach",
				text: "Połączenie designu i nowoczesnej architektury ze\n swobodną i kameralną atmosferą - to właśnie Lemon Resort SPA. Hotel skupiający w sobie ludzi z pasją, których zaangażowanie i indywidualne podejście do każdego klienta czynią ten obiekt wyjątkowym miejscem."
			},
			black: {
				heading: "Black energy",
				textHeading: "Numer 1 na rynku energetyków w Polsce",
				text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
			},
			balcerzak: {
				heading: "Balcerzak",
				textHeading: "Jeden z najnowocześniejszych w Polsce zakładów przetwórstwa mięsa",
				text: "Jest jednym z największych i najnowocześniejszych w Polsce zakładów przetwórstwa mięsa. Firma należy do niemieckiej grupy kapitałowej heristo ag, posiada uznaną i renomowaną markę, a co najistotniejsze – stabilną pozycję rynkową."
			},
			carSharing: {
				heading: "Carsharing",
				textHeading: "Nowy wymiar podróżowania po mieście",
				text: "Kompleksowe oprogramowanie carsahringowe, które umożliwa zarządzanie flotą transportową, zarządzanie procesem wynajmu pojazdu, automatyczne rozliczenia wynajmu, generowanie raportów i statystyk."
			},
			digitalSignage: {
				heading: "Digital Signage",
				textHeading: "Innowacyjny system dystrybucji reklamy cyfrowej ",
				text: "Innowacyjne systemy dystrybucji reklamy cyfrowej Digital Signage z powodzeniem wykorzystuje wiele branż komercyjnych – np. prężnie rozwijający się digital merchandising w miejscach sprzedaży, jak również szereg instytucji publicznych."
			},
			eLearning: {
				heading: "E-learning",
				textHeading: "Kompleksowy system kursów video online",
				text: "Samodzielnie zaprojektuj swój kurs video online w kilku prostych krokach. Dodawaj materiały video, definiuj nazwę, czas trwania i rodzaj dostępu. Twórz uporządkowane listy z podziałem na rozdziały. Przekonaj się, jakie to proste! "
			},
		},
		clients: {
			title: 'Klienci bardziej',
			text: 'Każda marka jest inna i wyjątkowa. Dobrze zdajemy sobie z tego sprawę, dlatego stawiamy na współpracę i indywidualne podejście do klienta. Wiedza i doświadczenie, zgromadzone przez lata pracy, pozwoliły nam przygotować już ponad 10&nbsp;000 udanych projektów dla największych firm z całego świata!',
		},
	},
	contact: {
		title: 'Kontakt',
		pageHeading: 'Porozmawiajmy bardziej',
		pageText: 'Każda marka jest inna i wyjątkowa. Dobrze zdajemy sobie z tego sprawę, dlatego stawiamy na współpracę i indywidualne...',
	},
	bardziej: {
		title: 'Bardziej',
		section1: 'Lata doświadczeń, pracy zespołowej, feedbacku Klientów i tysięcy projektów pozwoliły nam <strong>odkryć</strong>, kim jesteśmy i dokąd zmierzamy jako agencja. I co nas wyróżnia.',
		section2: 'Po prostu: <strong>Rozumiemy bardziej</strong>',
		section3: {
			part1: 'Już nie tylko jako interdyscyplinarna drużyna profesjonalistów,',
			part2: '<strong>ale zwinna grupa 359</strong>',
			part3: '(przyznajemy, że na hodowli jedwabników znamy się mniej niż bardziej 😊), skupiona na pełnym wsparciu marek, dobierająca zespół pod konkretne cele Klienta i reagująca na zmieniające się potrzeby.',
			button: 'Poznaj nas',
		},
		section4: {
			line1: 'Rozmawiajmy!',
			line2: 'Dialog to fundament sukcesu.',
			line3: 'Buduje się go poprzez ścisłą, wręcz partnerską',
			line4: 'współpracę, opartą na stałej i bieżącej komunikacji!'
		},
		section5: '<strong>Wspólne</strong> działanie, pełna <strong>koncentracja</strong> i zgrana <strong>ekipa</strong> - oto klucz do znajdowania <strong>najlepszych rozwiązań</strong>.',
		section6: {
			part1: 'Dobrze jest patrzeć z różnej',
			part2: 'perspektywy',
			part3: '<strong>Marketingowej. Technologicznej. Sprzedażowej.</strong>',
			part4: 'Dzięki szerokim kompetencjom zespołu nasze realizacje są kompletną, bezkompromisową odpowiedzią na realne potrzeby biznesu Klienta. '
		},
		section7: {
			line1: 'Z internetem jesteśmy na Ty!',
			line2: 'Przyjaźniąc się z digitalem,',
			line3: 'możesz więcej!'
		},
		section8: 'Wspierać, kierować i <strong>rozumieć bardziej</strong> - to przepis na <strong>dobrą współpracę!</strong> '
	},
	my: {
		text1: "Nazywamy się Intellect.",
		text2: "Tworzymy zgrany zespół pasjonatów Internetu i nowych technologii.",
		text3: "Jesteśmy interaktywną agencją kreatywną 360°, skupioną na pełnym wsparciu marek i nakierowaną na cele Klientów.",
		text4: "Od kilkunastu lat troszczymy się o biznesy na całym świecie!",
		text5: "Zespół jest naszą najważniejszą wartością.",
		text6: "Tworzymy zgraną drużynę specjalistów, z głową pełną kreatywnych pomysłów, którzy łączą codzienną pracę z prawdziwą pasją i hobby. Co nas wyróżnia? Uśmiechy na ustach, przyjazna atmosfera oraz otwarta komunikacja. Naprawdę lubimy to, co robimy i wierzymy, że widać to w naszych projektach.  Wspólnie zadbamy o sukces Twojej firmy!",
		career: "Kariera",
		career1: "Pomagamy<br/> w Twoim rozwoju",
		career2: "Doceniamy zaangażowanie i efekty pracy. Jeżeli widzimy, że się starasz – chętnie pomożemy w realizacji Twoich planów. Studia podyplomowe, szkolenie, a może kurs tańca? Nie ma problemu!",
		career3: "Ty wybierasz<br/> sprzęt, na jakim pracujesz",
		career4: "Komfort w pracy to podstawa. Dlatego na starcie dajemy Ci możliwość wyboru sprzętu, na którym wygodnie Ci się pracuje. Mówisz – masz.",
		career5: "Nie tylko praca!",
		career6: "Tworzymy zgrany team także po godzinach! Stawiamy na integrację – ognisko, paintball, wypad w góry – jesteśmy otwarci na wszelkie pomysły :)",
		offers: "Wybierz<br/> ofertę pracy",
		offerinfo: "Nie znalazłeś interesującego stanowiska?<br/> Nie poddawaj się!<br/> Cenimy odważnych ludzi ;)",
		writetous: "Napisz do nas"
	},
	competences: {
		websites: {
			text1: "Projektowanie<br/> i tworzenie<br/> stron www",
			text2: "Tworzenie <strong>stron internetowych</strong> to nie tylko nasza specjalność,<br/> to przede wszystkim",
			text3: "prawdziwa pasja.",
			text4: "Szukasz agencji, która  zrozumie <strong>Twoje potrzeby</strong>, podejdzie do projektu <strong>indywidualnie</strong> i pozwoli Ci wyróżnić się na tle konkurencji - czyli po prostu",
			text5: "zakasa rękawy i stworzy Ci stronę www, z której będziesz zadowolony?",
			text6: "My",
			text7: "Rozumiemy<br/> Bardziej",
			text8: "<p>Znamy doskonale wymagania wyszukiwarek, zachowania internautów, specyfikę różnych marek i branż, a wykonane przez nas strony internetowe (już ponad 10 tysięcy!), najlepiej potwierdzają nasze doświadczenie. To właśnie od tworzenia stron www, kilkanaście lat temu, zaczęła się historia agencji Intellect - pierwsze projekty tworzyliśmy w niewielkim garażu w Limanowej. Teraz wykonujemy realizacje dla firm z całego świata.</p><p>Przygotujemy dla Ciebie funkcjonalną, atrakcyjną stronę www, zgodną z najnowszymi trendami, która stanie się skutecznym narzędziem wizerunkowym oraz sprzedażowym dla Twojej marki. Uważamy, że kluczem efektywnego działania jest partnerstwo i współpraca, a projekt zamykamy dopiero wtedy, kiedy jesteśmy z niego naprawdę dumni. :)</p>",
			text9: "Twoja strona<br/> www<br/> z Intellectem",
			text10: "Sieć, to nasz drugi dom. Dlatego doskonale znamy prawa, jakimi się rządzi. Użytkownicy digitalu są wzrokowcami, ale co równie istotne, są też wygodni i niecierpliwi. Właśnie dlatego bardzo często to <strong>wygląd oraz architektura strony</strong> wpływają na to, czy",
			text11: "Twoi odbiorcy dokonają zakupu i czy wrócą do Ciebie w przyszłości.",
			text12: "Twoja strona wyróżni się w sieci, zadziała na Twoich odbiorców i zacznie sprzedawać!",
			text13: "Stworzyliśmy już tysiące projektów i naprawdę wiemy, że",
			text14: "dobrze przygotowana i ciekawa<br/> strona internetowa",
			text15: "przekłada się na",
			text16: "większą sprzedaż<br/> i świadomość marki.",
			text17: "Sekret tkwi w odpowiednim połączeniu<br/> jej najważniejszych elementów!",
			text18: "<h2>Zespół Intellectu<br/> tworzy stronę www<br/> od A do Z</h2>",
			text19: "Powierzając swój projekt w nasze ręce masz pewność, że zajmą się nim",
			text20: "<strong>doświadczeni specjaliści</strong>, którzy po prostu <strong>czują temat!</strong>",
			text21: "Przygotowaliśmy już tysiące stron i pracowaliśmy z różnymi markami – ale o tym najlepiej przekonasz się z ",
			text22: "naszego portfolio",
			text23: ", gdzie znajdziesz strony firm z całego świata, działające w najróżniejszych branżach.",
			text24: "Do każdego projektu podchodzimy indywidualnie, bo wiemy, że za każdym stoją inne wyzwania i potrzeby. Dlatego staramy się ",
			text25: "zrozumieć bardziej",
			text26: " specyfikę Twojej marki, jej cele i wymagania. Każdorazowo tworzymy stronę internetową w oparciu o ścisłą współpracę z Klientem i gruntowy research – tak, by najlepiej służyła",
			text27: "Twoim celom biznesowym.",
			text28: "Technologia, funkcjonalność<br/> i design",
			text29: "Wiemy, że strona internetowa to istotne narzędzie sprzedażowe i wizerunkowe. W pracy nad nią nie uznajemy więc kompromisów:",
			text30: "wykorzystujemy <strong>najnowsze technologie</strong>, dbamy o <strong>stabilność i bezpieczeństwo</strong> realizowanych projektów oraz <strong>optymalizację</strong> stron pod kątem widoczności w wyszukiwarkach.",
			text31: "Jesteśmy świadomi, że mobile to przyszłość Internetu, stosujemy więc",
			text32: "technologię responsywną",
			text33: "tak by strona wyświetlała się odpowiednio na każdym urządzeniu i każdej przeglądarce.",
			text34: "<p>Tym, co wyróżnia nasze projekty na tle innych, jest jednak przede wszystkim fakt, że strony (w zależności od wyboru klienta) tworzymy nie tylko na bazie systemów open source, ale przede wszystkim na autorskim systemie do zarządzania treścią – <strong>Intellect CMS</strong>. Obecnie korzysta z niego już kilkuset zadowolonych klientów. Jego funkcjonalność oraz obsługę rozwijamy nieustannie od kilkunastu lat, dzięki czemu oprogramowanie pozwala na szybkie, sprawne i intuicyjne administrowanie stroną - dzięki niemu masz pełną kontrolę nad witryną!</p><p>Dodatkowo, jako agencja 360 stopni, <strong>rozumiemy bardziej</strong> też znaczenie marketingu internetowego strona www nie tylko jest przygotowana z myślą o jej dalszej promocji, ale również gotowa na zaimplementowanie w niej metod z zakresu marketing automatization.</p>",
			text35: "kompleksowość,<br/> od A do Z<br/> w jednym miejscu",
			text36: "FAQ"
		},
		shops: {
			text1: "Projektowanie i tworzenie<br/> sklepów internetowych",
			text2: "ze specjalistami w dziedzinie e-commerce",
			text3: "Jeden system sklepowy - szereg funkcjonalności",
			features: {
				text1: "Bezpieczeństwo systemu",
				text2: "Otwarty kod systemu sklepu<br/> możliwość rozwoju strony <br/>przez firmy zewnętrzne",
				text3: "Możliwość rozbudowy o dodatkowe funkcjonalności",
				text4: "Wysoka skalowalność",
				text5: "Pełne dostosowanie funkcjonalności do indywidualnych potrzeb",
				text6: "Pełna zgodność sklepu ze standardami W3C i Google SEO",
				text7: "Najnowsze technologie – HTML5, CSS3, JS, RWD, PHP",
				text8: "Zaawansowane możliwości"
			}
		}
	},
	form: {
		formTitle: 'Skontaktuj się bardziej',
		send: 'Wyślij',
		downloadBrief: 'Pobierz<br/> BRIEF',
		name: 'Imię i nazwisko',
		company: 'Firma',
		mail: 'E-mail',
		phone: 'Telefon',
		message: 'Treść wiadomości',
		check1: 'Wyrażam zgodę na otrzymywanie informacji handlowych w rozumieniu art 10 ust. 2 Ustawy z dnia 18 lipca 2002 r.',
		uploadBrief: 'Załącz<br/> BRIEF'
	},
	footer: {
		copyright: '<b>© 2018 intellect.</b> Wszystkie prawa zastrzeżone.'
	},
	global: {
		turnonjs: "Ta stroa działa lepiej z włączoną obsługą javascript",
		more: "Zobacz więcej",
		features: "Sprawdź możliwości",
	},
	cookies: {
		accept: "Wyrażam zgodę"
	},
	projects: {
		global: {
			scope: 'Zakres realizacji:',
			scopeItem1: "Analiza przedwdrożeniowa nowej wersji serwisu",
			scopeItem2: "Badania potrzeb Klienta w zakresie wdrożenia katalogu produktów online",
			scopeItem3: "Opracowanie specyfikacji funkcjonalno-techniczne",
			scopeItem4: "Stworzenie modułu produktów oraz importu danych produktowych w standardzie ETIM",
			scopeItem5: "Stworzenie makiet funkcjonalnych UI/UX serwisu",
			scopeItem6: "Przygotowanie projektu graficznego serwisu",
			scopeItem7: "Zaprojektowanie i implementacja dedykowanego systemu CMS",
			scopeItem8: "Przygotowanie filmu korporacyjnego",
			scopeItem9: "Testy i wdrożenie",
			scopeItem10: "Redesign Identyfikacji Wizualnej",
			scopeItem11: "Optymalizacja SEO",
			casestudy: "Poznaj case study projektu",
			online: "Zobacz on-line",
			mock: "Makiety funkcjonalne",
			projects: "Projekt graficzny",
			desc: "Opis projektu i efekt:",
			realizations: "Zobacz nasze inne realizacje"
		},
		balcerzak: {
			banner: "Balcerzak Sp. z o.o.",
			title: "Balcerzak Sp. z o.o.",
			realization: {
				title: "Balcerzak",
				desc: "Zakłady przetwórstwa mięsa"
			},
			info: {
				title1: "Balcerzak Sp. z o.o.",
				title2: "Wyzwanie",
				text1: "Jest jednym z największych i najnowocześniejszych w Polsce zakładów przetwórstwa mięsa. Firma należy do niemieckiej grupy kapitałowej heristo ag, posiada uznaną i renomowaną markę, a co najistotniejsze – stabilną pozycję rynkową.",
				text2: "Głównym zadaniem było odświeżenie widoku serwisu, wdrożenie systemu Intellect CMS, dzięki któremu można swobodnie zarządzać treścią. "
			},
			desc: "Efektem prac było stworzenie serwisu o nowocześniejszym wyglądzie, przy jednoczesnym zachowaniu tradycyjnego DNA marki. Podczas pracy nad stroną zadbaliśmy także o SEO."
		},
		lemon: {
			banner: "Malowniczo położony hotel<br/> nad jeziorem w górach",
			title: "Lemon Resort SPA",
			realization: {
				title: "Lemon Resort SPA",
				desc: "Hotel"
			},
			info: {
				title1: "Lemon Resort SPA",
				title2: "Wyzwanie",
				text1: "Połączenie designu i nowoczesnej architektury ze swobodną i kameralną atmosferą - to właśnie Lemon Resort SPA. Hotel skupiający w sobie ludzi z pasją, których zaangażowanie i indywidualne podejście do każdego klienta czynią ten obiekt wyjątkowym miejscem.",
				text2: "Głównym zadaniem było stworzenie w pełni funkcjonalnej strony z dużą ilością wysokiej jakości multimediów i czytelnie wyeksponowanej oferty, nie tracąc jednocześnie na szybkości działania serwisu."
			},
			desc: "Efektem prac było stworzenie serwisu, który zachęca klientów video wysokiej rozdzielczości, przy jednoczesnym zachowaniu szybkości działania strony. Funkcjonalność strony www została poszerzona o integrację z modułem rezerwacji, a sam layout jest całkowicie responsywny."
		},
		black: {
			banner: "Black Energy Drink",
			title: "Black Energy Drink",
			realization: {
				title: "Black Energy Drink",
				desc: "Innowacyjny sprzęt nurkowy"
			},
			info: {
				title1: "Black Energy Drink",
				title2: "Wyzwanie",
				text1: "BLACK to niekwestionowany numer 1 na rynku energetyków w Polsce. Sygnowany twarzą Mike’a Tysona energetyk to uosobienie sukcesu, skuteczności i siły. BLACK nie uznaje kompromisów, ponieważ zwycięstwo ma we krwi.",
				text2: "Stworzenie nowej strony miało za zadanie budowanie i wzmacnianie świata marki Black Energy Drink oraz wartości z nią związanych."
			},
			desc: "Efektem prac było stworzenie serwisu o spójnym, nowoczesnym wyglądzie, który trafił do właściwej grupy docelowej."
		}
	},
	'Menu': 'Menu',
};

export default flatten(messages);
