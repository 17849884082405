import React, {Component} from 'react';

import "./CookieAlert.scss";
import BardziejButton from "../BardziejButton";

class CookieAlert extends Component {

	constructor(props) {
		super(props);

		this.state = {
			displayAlert: false,
		};

		this.storageKey = 'itllct_ck';
	}

	componentDidMount() {
		if (localStorage.getItem(this.storageKey) !== '1') {
			this.setState({
				displayAlert: true,
			});
		}
	}

	acceptCookies = () => {
		localStorage.setItem(this.storageKey, '1');

		this.setState({
			displayAlert: false,
		});
	};

	render() {
		if (this.state.displayAlert === false) {
			return null;
		}

		return (
			<div className="cookie-alert">
				<div className="cookie-alert__top">
					Ta strona używa informacji zapisanych za pomocą cookies i podobnych technologii m.in. w celu
					zapewnienia prawidłowego funkcjonowania witryny, prowadzenia analiz ukierunkowanych na dostosowanie
					witryny do oczekiwań Użytkowników i prezentowania reklam dostosowanych do ich upodobań.<br/>
					Korzystanie z naszej strony internetowej bez zmiany ustawień dotyczących cookies oznacza, że będą
					one zapisane w pamięci urządzenia. Więcej informacji można znaleźć w naszej <button
					onClick={this.props.showCookiePolicy} className="cookie-alert__button">Polityce prywatności</button>.
					Zapoznaj się z nią zanim wyrazisz zgodę.
				</div>
				<div className="cookie-alert__bottom">
					<BardziejButton modifier="bardziej-button--small bardziej-button--white" title="cookies.accept"
					                onClick={this.acceptCookies}/>
				</div>
			</div>
		)
	}
}

export default CookieAlert;
