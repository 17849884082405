import React from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';
import LanguagesPath from '../i18n/LanguagesPath';

const LocalizedLink = ({ to, intl: { locale }, ...props }) => {
  const originalPath = to;
  const LangsPath = new LanguagesPath(originalPath, locale);
  const slug = LangsPath.getSlug();

  return (
      <Link {...props} to={`${slug}`} />
  )
};

export default injectIntl(LocalizedLink);