import React, {Component} from 'react';
import withLayout from '../layout';

import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./bardziej.scss";
import Logo from './../shared/icons/logo-without-claim.svg';
import Image2 from './..//images/bardziej/2.jpg';
import Image2Invert from './..//images/bardziej/2-invert.jpg';
import Image5 from './..//images/bardziej/5.jpg';
import ContactSlide from "../components/Contact/ContactSlide";

import {injectIntl, FormattedHTMLMessage} from "react-intl";
import BardziejButton from "../components/BardziejButton";
import ScrollableVideo from "../components/My/ScrollableVideo";
import BardziejSlider from "../components/Bardziej/Slider";

class BardziejPage extends Component {

  componentDidMount() {
    document.body.classList.add('painted');
  }

  componentWillUnmount() {
    // document.body.classList.remove('painted');
  }

  render() {
    return (
      <>
        <StaticQuery
          query={graphql`
            query {
              image1: file(relativePath: { eq: "bardziej/1.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              image3: file(relativePath: { eq: "bardziej/3.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              image4: file(relativePath: { eq: "bardziej/4.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              image5: file(relativePath: { eq: "bardziej/5.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
            }
          `}
          render={data => (
            <div className="page page--bardziej">

              <BardziejSlider />

              <section className="section section--1 section--columns section--padding-x">
                <div className="wrapper">
                  <div className="section__layout section__layout--padding">
                    <div className="section__column">
                      <div className="text-content">
                        <FormattedHTMLMessage id="bardziej.section1" />
                      </div>
                    </div>
                    <div className="section__column">
                      <Logo className="logo-image" />
                    </div>
                  </div>
                </div>
              </section>

              <section className="section section--2 section--padding-x">
                <div className="wrapper">
                  <div className="section__layout section__layout--padding">
                    <div className="section__column">
                      <h1 className="text-content text-content--bigger text-content--center">
                        <FormattedHTMLMessage id="bardziej.section2" />
                      </h1>
                    </div>
                  </div>
                </div>
              </section>

              <section className="section section--3 section--columns section--padding-x">
                <div className="wrapper">
                  <div className="section__layout section__layout--padding">
                    <div className="section__column">
                     <ScrollableVideo video="/video/zoom_loop.mp4" />
                    </div>
                    <div className="section__column">
                      <div className="text-content">
                        <FormattedHTMLMessage id="bardziej.section3.part1" />
                        <div className="text-content text-content--medium">
                          <FormattedHTMLMessage id="bardziej.section3.part2" />
                        </div>
                        <FormattedHTMLMessage id="bardziej.section3.part3" />
                      </div>
                      <BardziejButton href="/my/" title="bardziej.section3.button" />
                    </div>
                  </div>
                </div>
              </section>

              <section className="section section--4 section--columns section--padding-x">
                <div className="wrapper">
                  <div className="section__layout">
                    <div className="section__column section__column--full text-content text-content--bigger text-content--center">
                      <div className="image mask-copy" style={{backgroundImage: `url('${Image2Invert}')`}}>
                        <h2 className="text-uppercase">
                          <FormattedHTMLMessage id="bardziej.section4.line1" />
                          <br />
                          <FormattedHTMLMessage id="bardziej.section4.line2" />
                        </h2>
                        <span className="text-spacing"><br /></span>
                        <span className="text-normal">
                          <FormattedHTMLMessage id="bardziej.section4.line3" />
                          <br />
                          <FormattedHTMLMessage id="bardziej.section4.line4" />
                        </span>
                      </div>
                      <div className="image mask-copy mask-copy--placeholder">
                        <strong className="text-uppercase">
                          <span data-text={this.props.intl.formatHTMLMessage({id: 'bardziej.section4.line1'})} />
                          <br />
                          <span data-text={this.props.intl.formatHTMLMessage({id: 'bardziej.section4.line2'})} />
                        </strong>
                        <span className="text-spacing"><br /></span>
                        <span className="text-normal">
                          <span data-text={this.props.intl.formatHTMLMessage({id: 'bardziej.section4.line3'})} />
                          <br />
                          <span data-text={this.props.intl.formatHTMLMessage({id: 'bardziej.section4.line4'})} />
                        </span>
                      </div>
                    </div>
                    <div className="section__column fill-full fill-full--right fill-full--w50">
                      <div style={{backgroundImage: `url('${Image2}')`}} className="image background-full background-full--right" />
                    </div>
                  </div>
                </div>
              </section>

              <div className="section-group">

                <section className="section section--5 section--columns section--padding-x">
                  <div className="wrapper">
                    <div className="section__layout section__layout--padding">
                      <div className="section__column">
                        <Img className='image' fluid={data.image3.childImageSharp.fluid} />
                      </div>
                      <div className="section__column">
                        <div className="text-content">
                          <FormattedHTMLMessage id="bardziej.section5" />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section section--6 section--columns section--columns-various section--padding-x">
                  <div className="wrapper">
                    <div className="section__layout">
                      <div className="section__column">
                        <div className="text-content">
                          <FormattedHTMLMessage id="bardziej.section6.part1" />
                          <br/><br/>
                          <span className="text-on-image">
                            <FormattedHTMLMessage id="bardziej.section6.part2" />
                          </span>

                          <FormattedHTMLMessage id="bardziej.section6.part3" />
                          <div className="text-content text-content--small">
                            <FormattedHTMLMessage id="bardziej.section6.part4" />
                          </div>
                        </div>
                      </div>
                      <div className="section__column">
                        <ScrollableVideo video="/video/panorama.mp4" />
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <section className="section section--7 section--columns section--padding-x">
                <div className="wrapper">
                  <div className="section__layout">
                    <div className="section__column fill-full fill-full--w50">
                      <div style={{backgroundImage: `url('${Image5}')`}} className="image background-full" />
                    </div>
                    <div className="section__column section__column--full text-content text-content--bigger text-content--center">
                      <div className="image mask-copy" style={{backgroundImage: `url('${Image5}')`}}>
                        <h2 className="text-uppercase">
                          <FormattedHTMLMessage id="bardziej.section7.line1" />
                        </h2>
                        <span className="text-spacing"><br /></span>
                        <span className="text-normal">
                          <FormattedHTMLMessage id="bardziej.section7.line2" />
                          <br />
                          <FormattedHTMLMessage id="bardziej.section7.line3" />
                        </span>
                      </div>
                      <div className="image mask-copy mask-copy--placeholder mask-copy--placeholder-left">
                        <strong className="text-uppercase">
                          <span data-text={this.props.intl.formatHTMLMessage({id: 'bardziej.section7.line1'})} />
                        </strong>
                        <span className="text-spacing"><br /></span>
                        <span className="text-normal">
                          <span data-text={this.props.intl.formatHTMLMessage({id: 'bardziej.section7.line2'})} />
                          <br />
                          <span data-text={this.props.intl.formatHTMLMessage({id: 'bardziej.section7.line3'})} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="section section--8 section--padding-y-small section--padding-x">
                <div className="wrapper">
                  <div className="section__layout section__layout--padding">
                    <div className="section__column">
                      <div className="text-content text-content--normal text-content--center">
                        <FormattedHTMLMessage id="bardziej.section8" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        />
        <ContactSlide standalone={true} titleTag="h2" />
      </>
    )
  }
};

const customProps = {
  localeKey: 'bardziej',
};

export default withLayout(customProps)(injectIntl(BardziejPage));
