import React, { Component } from 'react';
import PropTypes from 'prop-types';

import "./Tabs.scss";

import Tab from './Tab';

import ArrowLeft from '../../../shared/icons/shop/shop-arrow-left.svg';
import ArrowRight from '../../../shared/icons/shop/shop-arrow-right.svg';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

const defaultProps = {
  showNav: true,
  showArrows: true,
  showCounter: false
};

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
    showNav: PropTypes.bool,
    showArrows: PropTypes.bool,
    showCounter: PropTypes.bool,
    autoplay: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      countTabs: React.Children.toArray(this.props.children).length
    };
  }

  componentDidMount() {
    const {autoplay} = this.props;
    if (autoplay) {
      this.interval = setInterval(() => {
        this.onClickNextNavigate();
      }, 3000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onClickTabItem = tab => {
    this.setState({ activeTab: tab });
  };

  onClickPrevNavigate = () => {
    this.setState(prevState => ({
      activeTab: --prevState.activeTab >= 0 ? prevState.activeTab-- : prevState.countTabs - 1
    }));
  };

  onClickNextNavigate = () => {
    this.setState(prevState => ({
      activeTab: prevState.activeTab === prevState.countTabs - 1 ? 0 : prevState.activeTab + 1
    }));
  };

  render() {
    const {
      onClickTabItem,
      onClickPrevNavigate,
      onClickNextNavigate,
      props: {
        children,
        showNav,
        showArrows,
        showCounter,
        className,
        arrowLeft,
        arrowRight,
        labelTag
      },
      state: {
        activeTab,
        countTabs
      }
    } = this;

    return (
      <div className={`tabs ${className || ''}`}>
        {showArrows &&
          <>
            <div className="arrow arrow--prev" onClick={onClickPrevNavigate}>
              {arrowLeft ? arrowLeft : <ArrowLeft />}
            </div>
            <div className="arrow arrow--next" onClick={onClickNextNavigate}>
              {arrowRight ? arrowRight : <ArrowRight />}
            </div>
          </>
        }
        {showNav &&
          <div className="tabs__list">
            {React.Children.toArray(children).map((child, index) => {
              const { label } = child.props;

              return (
                <Tab
                  activeTab={activeTab}
                  key={index}
                  label={label}
                  labelTag={labelTag}
                  index={index}
                  onClick={onClickTabItem}
                />
              );
            })}
          </div>
        }
        {showCounter &&
          <div className="tabs__counter">
            {activeTab + 1} / {countTabs}
          </div>
        }
        <ReactCSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={500}
          transitionLeave={false}
        >
              {React.Children.toArray(children).map((child, index) => {
                if (index !== activeTab) return undefined;
                return (
                  <div className="tabs__content" key={index}>

                  {child.props.children}
                  </div>

                )
              })}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

Tabs.defaultProps = defaultProps;

export default Tabs;
